<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <input type="text" v-model="search_data.pr_number" v-validate="{ required: true }" id="pr_number" name="pr_number" class="form-control form-control-sm" placeholder="Search by PR number"/>
              <div class="invalid-feedback">PR number is required</div>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row"  v-if="Object.keys(purchase_request).length !== 0">
      <div class="col-md-12">
        <a-button type="success" class="btn btn-success mb-2 pull-right" :loading="pdfDownloadLoader" @click.prevent="pdfDownload(purchase_request.id)"><i class="fa fa-file-pdf-o mr-1"></i> Download PR</a-button>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="font-weight-bold">PR Number</label>
                  <p>{{ purchase_request.pr_number }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="font-weight-bold">Delivery On Before</label>
                  <p>{{ purchase_request.delivery_date }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <table class="table table-hover table-bordered">
                <thead>
                <tr>
                  <th>Product Name</th>
                  <th>QTY pcs</th>
                  <th>Unit Price</th>
                  <th>Sub Total</th>
                  <th>Total</th>
                  <th>Remarks</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>{{ product.product.name }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.unit_price }}</td>
                  <td>{{ product.sub_total }}</td>
                  <td>{{ product.total }}</td>
                  <td>{{ product.remarks }}</td>
                </tr>
                <!--  Total, Others, shipping-->
                <tr>
                  <th style="text-align: right" colspan="5">SUBTOTAL</th>
                  <td>{{ purchase_request.subtotal }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">OTHER</th>
                  <td>{{ purchase_request.others }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">TOTAL</th>
                  <td>{{ purchase_request.grand_total }}</td>
                </tr>
                </tbody>
              </table>
              <a-skeleton active :loading="loading"></a-skeleton>
              <h5 class="text-center text-secondary" v-if="products.length === 0 || !flag">
                <Empty :image="simpleImage"></Empty>
              </h5>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12"  v-if="Object.keys(purchase_request).length === 0 && flag">
        <div class="card">
          <div class="card-body">
            <p style="text-align: center; color: red; font-size: 16px">No purchase request found, try again</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      products: [],
      purchase_request: {},
      loader: false,
      flag: false,
      loading: false,
      btnLoading: false,
      pdfDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        pr_number: '',
      },
    }
  },
  mounted() {},
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = false
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/report/purchase-request/search', this.search_data)
            .then(response => {
              if (!response.data.error) {
                this.loading = false
                this.flag = true
                this.btnLoading = false
                const data = response.data
                this.products = data.products
                this.purchase_request = data.purchase_request
              } else {
                this.loading = false
                this.btnLoading = false
                this.flag = true
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    pdfDownload(purchaseRequestId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/purchase-request/pdf-download/' + purchaseRequestId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'purchase_request.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
